class NestedShowtimePicker {
  #container;

  pickerButtonClickHandler(button, hideElements, showElements, unactiveElements, activeElements) {
    LayoutUtils.hide(hideElements);
    unactiveElements.forEach((el) => {el.classList.remove('active');});
    activeElements.forEach((el) => {el.classList.add('active');});
    LayoutUtils.show(showElements);
    this.adjustContainerWidth();
    button.classList.add('active');
  }

  init(container) {
    this.#container = container;

    if (this.#container.closest('.controller-call_center_interface')) {
      LayoutUtils.show(document.querySelectorAll('.weeks'));
    }

    if(!document.querySelector('.weeks')) {
      LayoutUtils.show(document.querySelectorAll('.days'));
    }
    const forWeeks = container.querySelectorAll('.weeks > .button, .days, .hours, .minutes');
    const forDays = container.querySelectorAll('.days > .button, .hours, .minutes');
    const forHours = container.querySelectorAll('.hours > .button, .minutes');
    const dhm = container.querySelectorAll('.days, .hours, .minutes');
    const hm = container.querySelectorAll('.hours, .minutes');

    container.querySelectorAll('.weeks > .button').forEach((button) => {
      button.addEventListener('click', () => {
        this.pickerButtonClickHandler(button, 
          dhm, 
          container.querySelectorAll('.days[data-week="' + button.dataset.key + '"]'), 
          forWeeks, 
          container.querySelectorAll('.weeks'));
      });
    });
    
    container.querySelectorAll('.days > .button').forEach((button) => {
      button.addEventListener('click', () => {  
        this.pickerButtonClickHandler(button, 
          hm, 
          container.querySelectorAll('.hours[data-day="' + button.dataset.key + '"]'), 
          forDays, 
          container.querySelectorAll('.days'));
      });
    });
    
    container.querySelectorAll('.hours > .button').forEach((button) => {
      button.addEventListener('click', () => {
        const dataKey = button.dataset.key;
        const minutes = container.querySelector('.minutes[data-hour="' + dataKey + '"]');
        this.pickerButtonClickHandler(button, container.querySelectorAll('.minutes'), minutes, forHours, container.querySelectorAll('.hours'));
        button.classList.remove('inactive');
      
        if (dataKey.substring(dataKey.length - 3) == 'NOW') {
          button.dispatchEvent(new CustomEvent('showtime:selected', {detail: {showtime: 'NOW', showtimeDisplay: 'NOW', surgeShowtime: false}}));
        } else {
          this.minutesButtonActivize(container, minutes.querySelectorAll('.button')[0]);
        }
      });
    });

    container.querySelectorAll('.minutes > .button').forEach((button) => {
      button.addEventListener('click', () => {
        this.minutesButtonActivize(container, button);
      });
    });

    this.bindEvents();

    return this;
  }

  bindEvents() {
    this.#container.addEventListener('showtime-picker:setShowtime', (e) => {
      this.setShowtime(e.detail.showtime);
    });
  }

  minutesButtonActivize(container, button) {
    container.querySelectorAll('.minutes').forEach((el) => {el.classList.add('active');});
    container.querySelectorAll('.minutes > .button').forEach((el) => {el.classList.remove('active');});
    button.classList.add('active');

    const showtime = button.dataset.value;
    const showtimeDisplay = button.dataset.display;
    const surgeShowtime = button.dataset.surgeShowtime;

    container.dispatchEvent(new CustomEvent('showtime:selected', {detail: {showtime, showtimeDisplay, surgeShowtime}}));
  }

  clear() {
    this.#container.querySelectorAll('.active').forEach((el) => {el.classList.add('active');});
  }

  setShowtime(value) {
    if (!value || value === undefined) return false;

    value = value.replace(/\s{2,}/g, ' '); // remove extra spaces

    if (value !== '') {
      const isNow = (value === 'NOW');
      let isSuitableTime, hour, minute;

      if (isNow) {
        isSuitableTime = !!this.#container.querySelectorAll('.hours > .button[data-display="' + value + '"]').length;
        hour = this.#container.querySelector('.hours > .button[data-display="' + value + '"]');
      } else {
        isSuitableTime = !!this.#container.querySelectorAll('.minutes > .button[data-display="' + value + '"]').length ||
                                !this.#container.querySelectorAll('.minutes').length;
        minute = this.#container.querySelector('.minutes > .button[data-display="' + value + '"]');
        if (minute) {
          hour = this.#container.querySelector('.hours > .button[data-key="' + minute.closest('.minutes').dataset.hour + '"]');
        }
      }

      if (hour) {
        const day  = this.#container.querySelector('.days > .button[data-key="' + hour.closest('.hours').dataset.day + '"]');
        const week = this.#container.querySelector('.weeks > .button[data-key="' + day.closest('.days').dataset.week + '"]');
        if (week) {
          week.click();
        }
        
        if (day) {
          day.click();
        }
        
        hour.click();
      }
      
      if (!isNow && minute) minute.click();

      this.adjustContainerWidth();
      if (!isSuitableTime){
        this.clear();
        return true;
      } else {
        if (!isNow) this.minutesButtonActivize(this.#container, minute);
        return false;
      }
    } else {
      return false;
    }
  }

  adjustContainerWidth() {
    let width = 10;
    [...this.#container.querySelectorAll('.buttons_container')].filter(function(item) {
      return !item.classList.contains('hidden');
    }).forEach((item) => function(){
      width += item.clientWidth;
    });

    if (this.#container.querySelector('.container')) {
      this.#container.querySelector('.container').style.width = `${width}px`;
    }
  }
}

export default NestedShowtimePicker;